<template>
    <div>
        <v-snackbar
        v-model="snackbar"
        :top="top"
        :left="left"
        :color="color"
        :multi-line="mode === 'multi-line'"
        :timeout="timeout"
        :vertical="mode === 'vertical'"
        >
        {{ text }}
        <!--<v-btn
            dark
            flat
            @click="snackbar = false"
        >
            Close
        </v-btn>-->
        </v-snackbar>
    </div>
</template>

<script>
import { VueOfflineMixin } from 'vue-offline'
export default {
    name: 'network-status',
    mixins: [VueOfflineMixin],

    data: () => ({
        snackbar: false,
        color: '',
        mode: 'multi-line',
        top: false,
        left: false,
        timeout: -1,
        text: 'Connection error. No internet connection.',
        updateInterval: null
    }),

    compouted: {

    },

    methods: {
        newtworkStatus() {
            if(this.isOnline) {
                this.snackbar = false
                return
            }

            this.$store.commit('snackbarDisplay', false)
            this.snackbar = true
        }
    },

    created() {
        this.updateInterval = setInterval(() => {
            this.newtworkStatus()
        }, 5000)
    },

    mounted() {
        this.newtworkStatus()
    },

    destroyed() {
        clearInterval(this.updateInterval)
    }
}
</script>

<style scoped>

</style>


